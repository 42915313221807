@import "./../mixins/mixins";

.graphic-background-bottom-banner-illustration-homepage{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .graphic-background{
        &-left{
            margin-right: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
        &-right{
            margin-left: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
        &-center{
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
    }
    &[data-animation-css]{
        &:not(.is-animated){
            [data-bg-element-animation],
            [data-server-animation-bottom],
            [data-server-animation-center],
            [data-server-animation-top],
            [data-server-animation-top-2],
            [data-server-sm-animation-bottom],
            [data-server-sm-animation-center],
            [data-server-sm-animation-top],
            [data-server-sm-animation-top-2],
            [data-animation-cube],
            [data-animation-path]{
                animation: unset!important
            }
        }
        &.is-paused{
            [data-animation-cube],
            [data-animation-coin],
            [data-animation-dot],
            [data-animation-vertical-loop-sm],
            [data-animation-vertical-loop],
            [data-animation-up-down],
            [data-animation-down-up] {
                // animation: unset!important
                animation-play-state: paused;
            }
        }
        [data-bg-element-animation],
        [data-server-animation-bottom],
        [data-server-animation-center],
        [data-server-animation-top],
        [data-server-animation-top-2],
        [data-server-sm-animation-bottom],
        [data-server-sm-animation-center],
        [data-server-sm-animation-top],
        [data-server-sm-animation-top-2],
        [data-animation-cube]{
            opacity: 0;
        }
        &.is-animated{ 
            [data-bg-element-animation]{
                animation-name: sectionBackgroundFadeTop0-bottom-homepage;
                animation-duration: 1s;
                animation-delay: 0s;
                animation-timing-function: ease-in;
                animation-fill-mode: forwards;
                &[data-animation-type-1]{
                    animation-name: sectionBackgroundFadeTop1-bottom-homepage;
                }
                &[data-animation-type-2]{
                    animation-name: sectionBackgroundFadeTop2-bottom-homepage;
                }
                &[data-animation-type-3]{
                    animation-name: sectionBackgroundFadeTop3-bottom-homepage;
                }
                &[data-animation-type-4]{
                    animation-name: sectionBackgroundFadeTop4-bottom-homepage;
                }
            }
            [data-server-animation]{
                animation-duration: 1s;
                animation-delay: 0s;
                animation-timing-function: cubic-bezier(0.16,0,0,1);
                animation-fill-mode: forwards;
                &[data-server-animation-bottom]{
                    animation-name: serverAnimationBottom-bottom-homepage;
                }
                &[data-server-animation-center]{
                    animation-name: serverAnimationCenter-bottom-homepage;
                }
                &[data-server-animation-top]{
                    animation-name: serverAnimationTop-bottom-homepage;
                }
                &[data-server-animation-top-2]{
                    animation-name: serverAnimationTop2-bottom-homepage;
                }
                &[data-server-sm-animation-bottom]{
                    animation-name: serverSmAnimationBottom-bottom-homepage;
                }
                &[data-server-sm-animation-center]{
                    animation-name: serverSmAnimationCenter-bottom-homepage;
                }
                &[data-server-sm-animation-top]{
                    animation-name: serverSmAnimationTop-bottom-homepage;
                }
                &[data-server-sm-animation-top-2]{
                    animation-name: serverSmAnimationTop2-bottom-homepage;
                }
            }
            [data-animation-dot]{
                animation-duration: 3.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &[dot-opacity-up]{
                    animation-name: dotsLoopOpacity1-bottom-homepage;
                }
                &[dot-opacity-down]{
                    animation-name: dotsLoopOpacity2-bottom-homepage;
                }
            }
            [data-animation-cube]{
                will-change: transform, opacity;
                animation-name: cubeLoop-bottom-homepage;
                animation-duration: 2.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 0.32s;
                }
                &:nth-child(2){
                    animation-delay: 0.96s;
                }
                &:nth-child(3){
                    animation-delay: 1.24s;
                }
                &[data-animation-cube-1]{
                    --cubeStart: 60px;
                    --cubeGap: -50px;
                }
                &[data-animation-cube-2]{
                    --cubeStart: 40px;
                    --cubeGap: -70px;
                }
                &[data-animation-cube-3]{
                    --cubeStart: 20px;
                    --cubeGap: -70px;
                }
            }
            [data-animation-path]{
                will-change: transform, opacity;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &[data-animation-path-1]{
                    animation-name: pathAnimation1-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-2]{
                    animation-name: pathAnimation2-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-3]{
                    animation-name: pathAnimation3-bottom-homepage;
                    animation-duration: 1.5s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-4]{
                    animation-name: pathAnimation4-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-5]{
                    animation-name: pathAnimation5-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-6]{
                    animation-name: pathAnimation6-bottom-homepage;
                    animation-duration: 0.6s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-7]{
                    animation-name: pathAnimation7-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-8]{
                    animation-name: pathAnimation8-bottom-homepage;
                    animation-duration: 1.3s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-9]{
                    animation-name: pathAnimation9-bottom-homepage;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
            }
            [data-animation-up-down]{
                will-change: transform, opacity;
                animation-name: upDownReverse-bottom-homepage;
                animation-duration: 3s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
            }
            [data-animation-down-up]{
                animation-name: upDown-bottom-homepage;
                animation-duration: 3s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
            }
            [data-animation-delay-1]{
                animation-delay: 0.56s;
            }
            [data-animation-delay-2]{
                animation-delay: 0.64s;
            }
            [data-animation-delay-3]{
                animation-delay: 0.80s;
            }
            [data-animation-delay-4]{
                animation-delay: 0.96s;
            }
            [data-animation-duration-1]{
                animation-duration: 1s;
            }
            [data-animation-duration-2]{
                animation-duration: 2s;
            }
            [data-animation-duration-3]{
                animation-duration: 3s;
            }
        }
    }
    @keyframes sectionBackgroundFadeTop0-bottom-homepage {
        0%{
            transform: translate3d(0px, 0, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop1-bottom-homepage {
        0%{
            transform: translate3d(0px, 16px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop2-bottom-homepage {
        0%{
            transform: translate3d(0px, 32px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop3-bottom-homepage {
        0%{
            transform: translate3d(0px, 48px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop4-bottom-homepage {
        0%{
            transform: translate3d(0px, 56px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    
    @keyframes serverAnimationBottom-bottom-homepage {
        0%{
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes serverAnimationCenter-bottom-homepage {
        0%{
            transform: translate3d(0px, 16px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes serverAnimationTop-bottom-homepage {
        0%{
            transform: translate3d(0px, 32px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes serverAnimationTop2-bottom-homepage {
        0%{
            transform: translate3d(0px, 48px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    
    @keyframes serverSmAnimationBottom-bottom-homepage {
        0%{
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            opacity: 1;
        }
    }
    
    @keyframes serverSmAnimationCenter-bottom-homepage {
        0%{
            transform: translate3d(0px, 8px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes serverSmAnimationTop-bottom-homepage {
        0%{
            transform: translate3d(0px, 16px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    @keyframes serverSmAnimationTop2-bottom-homepage {
        0%{
            transform: translate3d(0px, 24px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0, 0px);
            opacity: 1;
        }
    }
    
    @keyframes dotsLoopOpacity1-bottom-homepage{
        0%, 100% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
    }
    @keyframes dotsLoopOpacity2-bottom-homepage{
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
    }
    
    @keyframes cubeLoop-bottom-homepage {
        0% {
            transform: translate3d(0px, var(--cubeStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translate3d(0px, var(--cubeGap), 0px);
            opacity: 0
        }
    }
    
    @keyframes upDown-bottom-homepage {
        0%, 100% {
            transform: translate3d(0px, 0px, 0px);
        }
        50% {
            transform: translate3d(0px, 15px, 0px);
        }
    }
    @keyframes upDownReverse-bottom-homepage {
        0%, 100% {
            transform: translate3d(0px, 0px, 0px);
        }
        50% {
            transform: translate3d(0px, -15px, 0px);
        }
    }
    
    @keyframes pathAnimation1-bottom-homepage {from {stroke-dashoffset: -467;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation2-bottom-homepage {from {stroke-dashoffset: -158;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation3-bottom-homepage {from {stroke-dashoffset: -656;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation4-bottom-homepage {from {stroke-dashoffset: 96;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation5-bottom-homepage {from {stroke-dashoffset: 96;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation6-bottom-homepage {from {stroke-dashoffset: -54;}to{stroke-dashoffset: 0;}}
    
    @keyframes pathAnimation7-bottom-homepage {from {stroke-dashoffset: 563;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation8-bottom-homepage {from {stroke-dashoffset: 358;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation9-bottom-homepage {from {stroke-dashoffset: 96;}to{stroke-dashoffset: 0;}}
}
